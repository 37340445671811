import React from 'react';
import { injectIntl, IntlShape } from 'react-intl';

import s from './PlayButton.scss';

interface IProps {
  onClick: () => void;
  intl: IntlShape;
  inverted?: boolean;
}

const PlayButtonComponent = ({ onClick, intl, inverted }: IProps) => {

  const playMessage = intl.formatMessage({ id: 'play', defaultMessage: 'Play' });

  return (
    <button className={s('playButton', { inverted })} onClick={onClick} aria-label={playMessage}>
      <span className={s.playButton__background} />
      <span className={s.playButton__inner}>{playMessage}</span>
    </button>
  );
};

export const PlayButton = injectIntl(PlayButtonComponent);
