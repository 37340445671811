import React, { useEffect, useRef } from 'react';

import s from './Tap.scss';

interface IProps {
  active?: boolean;
}

export const Tap = ({ active }: IProps) => {

  const frameCount = 27;
  // const fpsInterval = 1000 / frameCount;
  const fpsInterval = 1000 / 60;
  const currentLoopIndex = useRef<number>(0);
  const then = useRef<any>(Date.now());

  const sprite = useRef<HTMLDivElement>(null);
  const isPlaying = useRef<boolean>(false);

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    if (!active) {
      isPlaying.current = false;
    }

    if (active && !isPlaying.current) {
      play();
    }

  }, [active]);

  const play = () => {
      if (isPlaying.current) {
        return;
      }

      isPlaying.current = true;
      then.current = Date.now();
      step();
  };

  const step = () => {

    if (!isPlaying.current || !sprite.current) {
      return;
    }

    // sprite.current.className = `${s.tap__sprite} tapSpriteFrame${currentLoopIndex.current}`;

    requestAnimationFrame(step);

    const now = Date.now();
    const elapsed = now - then.current;

    if (elapsed > fpsInterval) {
      then.current = now - (elapsed % fpsInterval);

      sprite.current.className = `${s.tap__sprite} tapSpriteFrame${currentLoopIndex.current}`;

      currentLoopIndex.current ++;

      if (currentLoopIndex.current >= frameCount) {
        currentLoopIndex.current = 0;
      }
    }
  };

  return (
    <div className={s('tap')}>
      <div className={s.tap__imageWrap}>
        <div
          ref={sprite}
          className={`${s.tap__sprite} tapSpriteFrame0`}
          style={{
            backgroundImage: `url('https://s3-eu-west-1.amazonaws.com/kranavatn/images/glass-map-with-space.png')`,
          }}
        >
          {/* <img className={s.tap__image} /> */}
        </div>
      </div>
    </div>
  );
};
