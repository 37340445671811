import React, { useEffect, useRef, useState, Children, cloneElement } from 'react';
import gsap from 'gsap';
import { ViewportEnter } from 'components/viewport-enter/ViewportEnter';

import s from './Split.scss';

interface IProps {
  children?: React.ReactNode;
  animation: React.ReactElement<any>;
}

export const Split = ({ children, animation }: IProps) => {
  const [ backgroundAnimated, setBackgroundAnimated ] = useState(false);
  const [ active, setActive ] = useState(false);
  const [ playAnimation, setPlayAnimation ] = useState(false);
  const background = useRef<HTMLDivElement>(null);

  const enter = gsap.timeline({ paused: true, immediateRender: false });

  if (background.current) {
    enter.to(background.current, {
      scaleX: 1, transformOrigin: '0% 0%', ease: 'power3.inOut', duration: 1,
    });
  }

  useEffect(() => {
    if (!background.current) {
      return;
    }

    if (active && !backgroundAnimated) {
      setBackgroundAnimated(true);
      enter.play();
    }
  }, [active]);

  return (
    <>
      <ViewportEnter once={false} onEnter={() => { setActive(true); setPlayAnimation(true); }}>
        <div style={{ position: 'absolute', height: 1}} />
      </ViewportEnter>

      <div className={s('split')}>

        <ViewportEnter once={false} onEnter={() => { setPlayAnimation(false); }}>
          <div style={{ position: 'absolute', top: '-100vh', height: 1}} />
        </ViewportEnter>

        <div className={s.split__background} ref={background} />

        <div className={s.split__animation}>{cloneElement(animation, { active: playAnimation })}</div>

        <div className={s.split__content}>
          {Children.map(children, (child, i) => (
            <div key={i} className={s.split__item}>{child}</div>
          ))}
        </div>
      </div>
    </>
  );

};
