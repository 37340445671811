import * as React from 'react';

import { Link } from 'components/link/Link';

import s from './Button.scss';

interface IProps {
  to?: string;
  disabled?: boolean;
  visible: boolean;
  children: React.ReactNode;
  className?: string;
  [key: string]: any;
}

export const Button = ({
  to, children, className, disabled, visible, ...rest }: IProps) => {
  const passProps = { ...rest };
  const isLink = (typeof to !== 'undefined');
  const isExternal = isLink && /^((https?:)?\/\/|[0-9a-zA-Z]+:)/.test(to || '');

  passProps.className = s(className, 'button', {
    disabled,
    visible,
  });

  const content = (
    <span className={s.button__inner}>
      <span className={s.button__fill} />
      <span className={s.button__border} />
      <span className={s.button__text}>{children}</span>
    </span>
  );

  if (isExternal) {
    return <a target="_blank" rel="noopener noreferrer" href={to} {...passProps}>{content}</a>;
  }

  if (isLink) {
    return <Link to={to || '#'} {...passProps}>{content}</Link>;
  }

  passProps.disabled = disabled;

  return <button {...passProps}>{content}</button>;
};
