import React from 'react';

import Facebook from 'assets/svg/icons/social/facebook.svg';
import Twitter from 'assets/svg/icons/social/twitter.svg';
import Pinterest from 'assets/svg/icons/social/pinterest.svg';

import s from './Share.scss';

interface IProps {
  light?: boolean;
}

export const Share = ({ light }: IProps) => {

  const createUrls = () => {
    const urls: any = {};
    const url = window.location.href;
    const encodedUrl = encodeURI(url);

    urls.facebook = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
    urls.twitter = `https://twitter.com/intent/tweet?text=${encodedUrl}`;
    urls.pinterest = `https://pinterest.com/pin/create/button/?url=&media=${encodedUrl}`;

    return urls;
  };

  const onClick = (type: string) => {
    const urls = createUrls();

    window.open(urls[type], '_blank', 'width=600,height=300');
  };

  return (
    <div className={s('share', { light })}>
      <ul className={s.share__inner}>
        <li className={s.share__label}>Share</li>
        <li className={s.share__item}>
          <button title="Twitter" onClick={() => onClick('twitter')} className={s.share__link}>
            <Twitter />
          </button></li>
        <li className={s.share__item}>
          <button title="Facebook" onClick={() => onClick('facebook')} className={s.share__link}>
            <Facebook />
          </button>
        </li>
        <li className={s.share__item}>
          <button title="Pinterest" onClick={() => onClick('pinterest')} className={s.share__link}>
            <Pinterest />
          </button>
        </li>
      </ul>
    </div>
  );
};
