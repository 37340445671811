import React from 'react';

import { Container } from 'components/layout/Container';

import s from './Change.scss';

interface IProps {
  children?: React.ReactNode;
  text1: string;
  text2: string;
  active?: boolean;
}

export const Change = ({ children, active, text1, text2 }: IProps) => {

  return (
    <div className={s('change', { active })}>

      <div className={s.change__inner}>

        <Container>
          <h2 className={s.change__heading}>
            <span className={s.change__text1}>{text1}</span>
            <span className={s.change__text2}> {text2}</span>
          </h2>
        </Container>
        {children}

      </div>
    </div>
  );
};
