import React, { useRef, useEffect, useState, useContext } from 'react';
import gsap from 'gsap';

import { UIContext } from 'context/ui';

import { useOffset } from 'hooks/use-offset';

import s from './Bottle.scss';

interface IProps {
  loaded?: boolean;
}

export const Bottle = ({ loaded }: IProps) => {

  // scroll length for 3 x bottle transitions
  const offsetRefA = useRef<HTMLDivElement>(null);
  const offsetRefB = useRef<HTMLDivElement>(null);
  const offsetRefC = useRef<HTMLDivElement>(null);
  //  scroll for bottle rotation sequence
  const offsetRefAnimation = useRef<HTMLDivElement>(null);

  const bottleRef = useRef<HTMLDivElement>(null);
  const bottleInner = useRef<HTMLDivElement>(null);
  const canvas = useRef<HTMLCanvasElement>(null);

  const { isMobile } = useContext<any>(UIContext);

  const frames = 100;

  const [index, setIndex] = useState(0);

  const imagePrefix = 'https://kranavatn.s3-eu-west-1.amazonaws.com';

  // transform scale & rotate
  const offsetA = useOffset(offsetRefA, 'item') || 0;
  // revert to centre
  const offsetB = useOffset(offsetRefB, 'item') || 0;
  // fade out
  const offsetC = useOffset(offsetRefC, 'item') || 0;
  //  rotation animation sequence
  const offsetAnimation = useOffset(offsetRefAnimation, 'item') || 0;

  useEffect(() => {
    if (!bottleInner.current) {
      return;
    }

    // set default props on mount
    gsap.set(bottleInner.current, { rotation: -24, scale: 2.5, y: '20vh'});
  }, []);

  useEffect(() => {
    if (!bottleRef.current) {
      return;
    }

    // loop twice
    const computedFrame = offsetAnimation * (frames * 2);

    if (offsetAnimation > 0) {
      if (offsetAnimation < 0.5) {
        setIndex(Math.floor(computedFrame));
      } else if (offsetAnimation < 1) {
        setIndex(Math.floor(computedFrame - frames));
      }
    }

  }, [offsetAnimation]);

  useEffect(() => {
    if (!bottleInner.current) {
      return;
    }

    const cssOffsetA = offsetA > 0 ? (1 - offsetA) : 1;

    if (offsetA > 0) {
      gsap.set(bottleInner.current, {
        rotation: cssOffsetA * -24,
        scale: (cssOffsetA * 1.5) + 1,
        y: `${cssOffsetA * 20}vh`},
      );
    }

    //  if we are using canvas
    if (canvas.current) {
      renderCanvas(index);
    }

  }, [offsetA]);

  useEffect(() => {
    if (!bottleInner.current) {
      return;
    }

    const x = isMobile ? -1 : -50;

    if (offsetB > 0) {
      gsap.set(
        bottleInner.current,
        {
          rotation: offsetB * 24,
          scale: (offsetB * 0.5) + 1,
          x: `${offsetB * x}%`,
          y: `${offsetB * 10}vh`,
      });
    }

  }, [offsetB]);

  useEffect(() => {
    if (!bottleRef.current) {
      return;
    }

    bottleRef.current.style.setProperty('--opacity', offsetC.toString());

    if (offsetC === 1) {
      bottleRef.current.style.setProperty('visibility', 'hidden');
    } else {
      bottleRef.current.style.setProperty('visibility', 'visible');
    }

  }, [offsetC]);

  const renderCanvas = (position: number) => {
    if (!canvas.current) {
      return;
    }

    const ctx = canvas.current.getContext('2d');
    const img = new Image();

    if (!ctx) {
      return;
    }

    if (position <= frames) {
      img.onload = () => {
        ctx.clearRect( 0, 0, 700, 1080 );
        ctx.drawImage( img, 0, 0 );
      };

      img.src = imagePrefix + '/png/' + position + '.png';
    }
  };

  const images = [(
    <picture key={0} className={s('bottle__picture')} style={{ opacity: index < 1 ? 1 : 0}}>
      <source srcSet={`${imagePrefix}/webp/${0}.webp`} type="image/webp" />
      <source srcSet={`${imagePrefix}/png/${0}.png`} type="image/png" />
      <img src={`${imagePrefix}/png/${0}.png`} width="700" height="1080" />
    </picture>
  )];

  for (let i = 1; i < frames; i ++) {
    images.push(
    <picture key={i} className={s('bottle__picture')} style={{ opacity: i === index ? 1 : 0 }}>
      <source srcSet={`${imagePrefix}/webp/${i}.webp`} type="image/webp" />
      <source srcSet={`${imagePrefix}/png/${i}.png`} type="image/png" />
      <img src={`${imagePrefix}/png/${i}.png`} width="700" height="1080" />
    </picture>,
    );
  }

  return (
    <>
      <div className={s('offsetA')} ref={offsetRefA} />
      <div className={s('offsetB')} ref={offsetRefB} />
      <div className={s('offsetC')} ref={offsetRefC} />
      <div className={s('offsetAnimation')} ref={offsetRefAnimation} />
      <div className={s('bottle', { loaded })} ref={bottleRef}>
        <div className={s.bottle__container}>
          <div className={s.bottle__inner} ref={bottleInner}>
            {images}
            {/* <canvas ref={canvas} className={s('bottle__picture')} width="700" height="1080" /> */}
          </div>
        </div>
      </div>
    </>
  );
};
