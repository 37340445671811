import React from 'react';

import { Offset } from 'components/offset/Offset';

import s from './Progress.scss';

export const Progress = (props: { backgroundColor: string }) => {

  return (
    <>
      <Offset offsetType="window">
        <div className={s('progress')}>
          <div className={s.progress__inner} style={{ backgroundColor: props.backgroundColor }} />
        </div>
      </Offset>
    </>
  );

};

Progress.defaultProps = {
  backgroundColor: '#161616',
};
