import React, { cloneElement } from 'react';
import { Offset } from 'components/offset/Offset';

interface IProps {
  active?: boolean;
  previous?: boolean;
  className?: string;
  children: React.ReactElement<any>;
  index?: number;
}

export const Item = ({ children, active, index }: IProps) => (
  <Offset>
    <div>
      {cloneElement(children, {
        active,
        index,
      })}
    </div>
  </Offset>
);
