import React from 'react';
import { Offset } from 'components/offset/Offset';

import s from './SplitContent.scss';

interface IProps {
  text: string;
  backgroundText: string;
}

export const SplitContent = ({ text, backgroundText }: IProps) => {

  return (
    <div className={s('splitContent')}>
        <h2 className={s.splitContent__backgroundText}>
          <Offset>
            <span className={s.splitContent__backgroundInner}>{backgroundText}</span>
          </Offset>
        </h2>
      <p className={s.splitContent__text}>{text}</p>
    </div>
  );

};
