import React, { useEffect, useRef } from 'react';
import { useOffset } from 'hooks/use-offset';

import s from './Video.scss';

interface IProps {
  children?: React.ReactNode;
  setThemeLight?: (args: boolean) => void;
}

export const Video = ({ children, setThemeLight }: IProps) => {

  const refEl = useRef<HTMLDivElement>(null);
  const offset = useOffset(refEl);

  useEffect(() => {
    if (!refEl.current || !offset || offset < 0.5) {
      return;
    }

    setThemeLight!(offset >= 0.95);

  }, [offset]);

  return (
    <div className={s('video')} ref={refEl}>
      {children}
    </div>
  );
};
