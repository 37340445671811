import React, { cloneElement, useState, useContext, ReactElement, ReactNode, Children, useEffect } from 'react';

import { UIContext } from 'context/ui';

import { Bottle } from '../bottle/Bottle';
import { Button } from '../button/Button';
import { Loading } from '../loading/Loading';
import { Progress } from '../../progress/Progress';
import { OutroModal } from '../outro/OutroModal';

import s from './Wrapper.scss';
import { Share } from '../share/Share';

interface IProps {
  header: ReactElement<any>;
  hero: ReactElement<any>;
  outro: ReactElement<any>;
  children?: ReactNode;
  social?: ReactElement<any>;
  countPledge: (signature: string) => Promise<number>;
}

export const Wrapper = ({ header, hero, outro, children, social, countPledge }: IProps) => {
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [outroVisible, setOutroVisible] = useState(false); // outro is scrolled to
  const [outroModalVisible, setOutroModalVisible] = useState(false); // outro is open as modal
  const [themeLight, setThemeLight] = useState(false); // header theming
  const [pledgeComplete, setPledgeComplete] = useState(false);
  const [count, setCount] = useState<number>(-1);
  const { isMobile } = useContext<any>(UIContext);

  async function setCompleteHandler(signature: string, state: boolean) {
    setPledgeComplete(state);

    const currentCount = await countPledge(signature);
    setCount(currentCount);
  }

  useEffect(() => {
    setThemeLight(!isMobile);
  }, [isMobile]);

  return (
    <>
      {cloneElement(header, {
        light: themeLight || outroModalVisible,
        showNav: !outroModalVisible,
      })}
      <Share light={themeLight || outroModalVisible} />

      <div className={s('wrapper')}>
        <Loading loadingComplete={loadingComplete} setLoadingComplete={setLoadingComplete} />
        <Progress backgroundColor="#1081ab" />

        {social}

        {cloneElement(hero, {
          loaded: loadingComplete,
          isMobile,
          setThemeLight,
        })}

        <div className={s('wrapper__children')}>
        {Children.map(children, (child, i) => (
          cloneElement(child as React.ReactElement<any>, {key: i, setThemeLight})))}

          <Bottle loaded={loadingComplete} />
        </div>

        <div className={s.wrapper__button}>
          <Button visible={!outroVisible && !pledgeComplete} onClick={() => { setOutroModalVisible(true); }}>
            Take the challenge
          </Button>
        </div>

        {cloneElement(outro, {
          complete: pledgeComplete,
          setComplete: setCompleteHandler,
          setActive: setOutroVisible,
          active: outroVisible,
          count,
          })}
      </div>

      <OutroModal
        active={outroModalVisible}
        onClose={() => { setOutroModalVisible(false); }}
      >
        {cloneElement(outro, { complete: pledgeComplete, setComplete: setCompleteHandler, count })}
      </OutroModal>
    </>
  );
};
