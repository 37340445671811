import * as React from 'react';

interface IArgs {
  offsetType: 'item' | 'window';
}

export const useOffset = (ref: React.RefObject<any>, offsetType?: IArgs['offsetType'], clamped = true) => {

    const [ offset, setOffset ] = React.useState(0);

    if (!offsetType) {
      offsetType = 'item';
    }

    const onScroll = () => {
      requestAnimationFrame(() => computeOffset());
    };

    const computeOffset = () => {
      const windowHeight = window.innerHeight;
      const windowScroll = window.scrollY;
      const documentHeight = document.body.scrollHeight;
      // @ts-ignore
      const { top, height } = ref.current!.getBoundingClientRect();

      const math = offsetType === 'item' ?
        (windowHeight - top) / (windowHeight + height) :
        windowScroll / (documentHeight - windowHeight);

      const offsetValue = clamped ? (Math.max(0, Math.min(1, math))) : math;

      setOffset(offsetValue);
    };

    React.useEffect(() => {
      if (typeof window === undefined) {
        return;
      }

      window.addEventListener('scroll', onScroll, { passive: true });
      computeOffset();

      return () => {
        window.removeEventListener('scroll', onScroll);
      };
    }, []);

    if (!ref.current) {
      return;
    }

    return offset;
};

useOffset.defaultProps = {
  offsetType: 'item',
};
