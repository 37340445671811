import React, { forwardRef } from 'react';
import CloseSvg from 'assets/svg/icons/close.svg';

import s from './CloseButton.scss';

interface IProps {
  onClick: () => void;
}

export const CloseButton = forwardRef(({ onClick, ...rest }: IProps, ref: any) => {

  return (
    <button className={s.closeButton} onClick={onClick} ref={ref} {...rest}>
      <CloseSvg className={s.closeButton__svg} />
    </button>
  );
});
