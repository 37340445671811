import React, { useContext, useRef } from 'react';

import { UIContext } from 'context/ui';

import { HeroBackground } from 'components/hero-background/HeroBackground';

import Hero from 'components/kranavatn/hero/Hero';
import Meta from 'components/meta/Meta';

import { Item } from 'components/kranavatn/item/Item';
import { Content } from 'components/kranavatn/content/Content';
import { Header } from 'components/kranavatn/header/Header';
import { Change } from 'components/kranavatn/change/Change';
import { Outro } from 'components/kranavatn/outro/Outro';
import { Split } from 'components/kranavatn/split/Split';
import { SplitContent } from 'components/kranavatn/split/SplitContent';
import { Tap } from 'components/kranavatn/tap/Tap';
import { Video } from 'components/kranavatn/video/Video';
import { Wrapper } from 'components/kranavatn/wrapper/Wrapper';

import KranavatnLogo from 'assets/svg/kranavatn-logo.svg';

// import { Embed } from 'components/slices/Embed';

async function countPledge(signature: string) {
  const body = {
    signature,
  };

  const url = new URL('/.netlify/functions/counter', window.location.origin);
  const options = {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(body),
  };

  try {
    const result = await fetch(url.href, options);

    if (!result.ok) {
      // tslint:disable-next-line
      console.error('Unable to count pledge', result.status, result.statusText);
    } else {
      const jsonData = await result.json();
      return jsonData.count;
    }
  } catch (e) {
    // tslint:disable-next-line
    console.error('Unable to count pledge', e);
  }

  return Promise.resolve(-1);
}

export default () => {

  const { setHeaderVisible, setFooterVisible } = useContext<any>(UIContext);

  const initialRender = useRef(true);

  if (initialRender.current === true) {
    setHeaderVisible(false);
    setFooterVisible(false);

    initialRender.current = false;
  }

  const metaDescription = 'Drink responsibly: You will save around $50* by drinking tap water on your trip';

  const navLink = process.env.GATSBY_KRANAVATN ? '/partners' : '/kranavatn/partners';

  return (
  <>
    <Meta
      title="Kranavatn"
      description={metaDescription}
      image="https://s3-eu-west-1.amazonaws.com/kranavatn/images/share-image.jpg"
      imageAlt={metaDescription}
    />

    <Wrapper
      header={(
        <Header
          logoLink="https://inspiredbyiceland.com/"
          navLink={navLink}
          navLinkText="Our partners"
        />
      )}
      hero={
        <Hero
          heading="Drink Responsibly"
          kicker={<KranavatnLogo />}
          text="A premium water brand from Iceland"
          backgroundVideo={(
            <HeroBackground
              video="https://s3-eu-west-1.amazonaws.com/kranavatn/video/hero.mp4"
              image="https://s3-eu-west-1.amazonaws.com/kranavatn/images/hero-lg.jpg"
              imageMobile="https://s3-eu-west-1.amazonaws.com/kranavatn/images/hero-sm.jpg"
            />
          )}
        />
      }

      countPledge={countPledge}

      outro={(
        <Outro
          heading={<>Take the <KranavatnLogo /> challenge</>}
          text="I pledge to make kranavatn my drink of choice in Iceland.
          I will drink from reusable bottles, and responsibly dispose of all my waste."
          completedHeading="Thank you for taking the challenge"
          completedText="We would very much appreciate it if you would help us spread the word about The Kranavatn Challenge.
          Please consider sharing this."
          signaturePlaceholder="Draw your signature here"
          signatureInstructions="Use your mouse to draw your signature above"
          countText="Have taken the challenge"
        />
      )}
    >

      <Item>
        <Content
          value={65}
          text="of all travelers say they use more bottled water on vacation than they do at home"
          backgroundText="sixty five percent"
        />
      </Item>

      <Item>
        <Content
          value={70}
          text="Of all travelers say they don’t trust tap water in their destination"
          backgroundText="seventy percent"
        />
      </Item>

      <Item>
        <Content
          value={80}
          text="of all plastic bottles end up in landfills or in the ocean"
          backgroundText="eighty percent"
        />
      </Item>

      <Item>
        <Change text1="It’s time" text2="for a change" />
      </Item>

      <Item>
        <Split animation={<Tap />}>
          <SplitContent text="“Kranavatn” is Icelandic for tap water" backgroundText="Kranavatn" />
          <SplitContent
            text="Kranavatn is pure springwater, naturally filtered through lava for centuries"
            backgroundText="Pure springwater"
          />
          <SplitContent text="Before it reaches your tap" backgroundText="Tap water" />
        </Split>
      </Item>

      <Video>
        {/* <Embed
          id={259411563}
          caption="Introducing “kranavatn”"
          image="https://s3-eu-west-1.amazonaws.com/kranavatn/images/hero-lg.jpg"
          duration="15:26"
        /> */}
      </Video>
    </Wrapper>
  </>
  );
};
