import React, { useEffect } from 'react';

import { useKeyDown } from 'hooks/use-keydown';

import { CloseButton } from 'components/close-button/CloseButton';

import s from './HeroEmbed.scss';

interface IProps {
  youtubeID: string;
  play: (args: boolean) => void;
  playing: boolean;
}

export const HeroEmbed = ({ youtubeID, playing, play }: IProps) => {

  const keys = useKeyDown();

  useEffect(() => {
    if (keys.includes(27) && playing) {
      play(false);
    }
  }, [keys]);

  return (
    <div className={s('heroEmbed', { playing })}>
      {playing && (
        <>
        <iframe
          className={s.heroEmbed__embed}
          src={`https://www.youtube.com/embed/${youtubeID}?controls=0&autoplay=1&modestbranding=1`}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          seamless
        />
        <div className={s.heroEmbed__close}>
          <CloseButton onClick={() => { play(false); }} />
        </div>
        </>
      )}
    </div>
  );
};
