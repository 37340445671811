import React, { Component } from 'react';
import gsap from 'gsap';
import { debounce } from 'lodash';

import { HeroEmbed } from 'components/hero-embed/HeroEmbed';
import { Container } from 'components/layout/Container';
import { Row } from 'components/layout/Row';
import { PlayButton } from 'components/play-button/PlayButton';
import { ScrollIndicator } from 'components/scroll-indicator/ScrollIndicator';
import { ViewportEnter } from 'components/viewport-enter/ViewportEnter';

import s from './Hero.scss';

interface IProps {
  videoID?: string;
  backgroundVideo: React.ReactElement<any>;
  children?: React.ReactNode;
  heading: string;
  kicker: string | React.ReactElement<any>;
  text: string;
  loaded?: boolean;
  isMobile?: boolean;
  setThemeLight?: (args: boolean) => void;
}

export default class Hero extends Component<IProps> {

  state = {
    animationComplete: false,
    exitComplete: false,
    active: false,
    videoPlaying: false,
  };

  heroTransformNode = React.createRef<HTMLDivElement>();
  videoWrapperNode = React.createRef<HTMLDivElement>();
  videoInnerNode = React.createRef<HTMLDivElement>();
  contentNode = React.createRef<HTMLDivElement>();

  videoTimeline = gsap.timeline({
    paused: true,
    onComplete: () => this.setState({ animationComplete: true }),
  });

  exitTimeline = gsap.timeline({ paused: true });

  initTimeline = () => {
    this.videoTimeline.addLabel('startVideo', 0);
    this.exitTimeline.addLabel('startExit', 0);
    const exitDuration = 0.7;

    const ease = 'power3.inOut';

    this.videoTimeline.to(
      this.videoWrapperNode.current!, 1,
      {xPercent: -(5.75 / 12) * 100, ease }, 'startVideo')
      .to(
        this.videoInnerNode.current!, 1,
        {xPercent: 30, ease }, 'startVideo');

    this.exitTimeline
      .fromTo(
        this.heroTransformNode.current!,
        exitDuration,
        { autoAlpha: 1 },
        { autoAlpha: 0, ease }, 'startExit')
      .fromTo(
        [`.${s.hero__heading}`, `.${s.hero__playButton}`],
        exitDuration,
        { y: 0 },
        { y: -300, ease }, 'startExit')
      .fromTo(
        [`.${s.hero__text}`, `.${s.hero__kicker}`],
        exitDuration / 2,
        { autoAlpha: 1 },
        { autoAlpha: 0, ease }, 'startExit');
  }

  resetAnimation = () => {
    gsap.set([
      this.videoWrapperNode.current!,
      this.videoInnerNode.current!,
      this.heroTransformNode.current!,
      this.contentNode.current!.childNodes,
    ], {clearProps: 'all'});

    this.setState({
      animationComplete: true,
      exitComplete: true,
    });
  }

  onResize = debounce(() => {
      if (!this.props.isMobile && window.scrollY > 0) {
        this.setState({ exitComplete: true }, () => {
          this.exitTimeline.seek('-=0');
          this.videoTimeline.seek('-=0');
        });
      }
    }, 200);

  componentDidMount() {
    this.initTimeline();

    if (window === undefined) {
      return;
    }

    window.scrollTo(0, 0);

    // check hero is not visible on resize
    window.addEventListener('resize', this.onResize, { passive: true });
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps.loaded !== this.props.loaded) {
      if (this.props.loaded && !this.props.isMobile) {
        this.videoTimeline.seek(0).play();
      }
    }

    if (prevProps.isMobile !== this.props.isMobile) {

      // set header theming to light if not mobile
      this.props.setThemeLight!(!this.props.isMobile);

      if (this.props.isMobile) {
        this.resetAnimation();
      } else {
        if (typeof window === undefined) {
          return;
        }
        window.scrollTo(0, 0);
        this.videoTimeline.seek(0).play();
      }
    }
  }

  animateOut = () => {
    if (!this.props.isMobile && !this.state.exitComplete) {
      this.exitTimeline.seek(0);
      this.setState({ exitComplete: true }, () => this.exitTimeline.play());
      this.props.setThemeLight!(false);
    }
  }

  animateIn = () => {
    if (!this.props.isMobile && this.state.exitComplete) {
      this.exitTimeline.seek('-=0');
      this.setState({ exitComplete: false }, () => this.exitTimeline.reverse(0));
      this.props.setThemeLight!(true);
    }
  }

  playVideo = (args: boolean) => {
    this.setState({ videoPlaying: args });
  }

  componentWillUnmount() {
    if (this.videoTimeline) {
      this.videoTimeline.kill();
    }

    if (this.exitTimeline) {
      this.exitTimeline.kill();
    }

    window.removeEventListener('resize', this.onResize);
  }

  render() {

    const { videoID, backgroundVideo, heading, kicker, text, loaded } = this.props;

    return (
      <>
        <ViewportEnter once={false} onEnter={() => { this.animateIn(); }} onExit={() => { this.animateOut(); }}>
          <div className={s.hero__waypoint} />
        </ViewportEnter>

        <div className={s('hero', { loaded, exited: this.state.exitComplete })}>
          <div className={s.hero__transform} ref={this.heroTransformNode}>

            <div className={s.hero__inner}>
              <div className={s.hero__video} ref={this.videoWrapperNode}>
                <div className={s.hero__videoInner} ref={this.videoInnerNode}>
                  {backgroundVideo}
                </div>
              </div>

              <div className={s.hero__content}>
                <Container>
                  <Row>
                    <div className={s.hero__contentInner} ref={this.contentNode}>
                      <div className={s.hero__kicker}>{kicker}</div>
                      <h1 className={s.hero__heading}>{heading}</h1>
                      <p className={s.hero__text}>{text}</p>
                      {videoID && (
                        <div className={s.hero__playButton}>
                          <PlayButton inverted onClick={() => { this.playVideo(true); }} />
                        </div>
                      )}
                    </div>
                  </Row>
                </Container>
              </div>
            </div>
          </div>

        </div>
        <div className={s.hero__scrollIndicator}><ScrollIndicator text="Scroll to learn more" /></div>

        {videoID && (
          <div className={s.hero__embed}>
            <HeroEmbed youtubeID={videoID} play={this.playVideo} playing={this.state.videoPlaying} />
          </div>
        )}
      </>
    );
  }

}
