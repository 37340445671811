import React, { useRef, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import { FormattedMessage } from 'react-intl';

import { useOffset } from 'hooks/use-offset';

import { Container } from 'components/layout/Container';
import { InlineShare } from 'components/inline-share/InlineShare';
import { Signature } from 'components/signature/Signature';

import s from './Outro.scss';

interface IProps {
  text: string;
  completedText: string;
  heading: React.ReactElement<any> | string;
  completedHeading: string;
  signaturePlaceholder?: string;
  signatureInstructions?: string;
  count?: number;
  countText: string;
  active?: boolean;
  setActive?: (args: boolean) => void;
  complete?: boolean;
  setComplete?: (signature: string, args: boolean) => void;
}

export const Outro = ({
  heading,
  text,
  completedHeading,
  completedText,
  signaturePlaceholder,
  signatureInstructions,
  count,
  countText,
  active,
  setActive,
  complete,
  setComplete,
}: IProps) => {
  const offsetEl = useRef<HTMLDivElement>(null);
  const offset = useOffset(offsetEl);

  const url = 'https://kranavatn.inspiredbyiceland.com/';
  const encodedUrl = encodeURI(url);

  const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;
  const twitterLink = `https://twitter.com/intent/tweet?text=Take%20the%20Kranavatn%20challenge%20${encodedUrl}`;

  useEffect(() => {
    if (!offsetEl.current || !offset || !setActive) {
      return;
    }

    setActive!(offset > 0.1);

  }, [offset]);

  return (
  <>
    <div className={s('outro', { active })} ref={offsetEl}>
      <div className={s.outro__backdrop} />
        {!complete && (
          <div className={s.outro__content}>
          <Container>
            <h2 className={s.outro__heading}>{heading}</h2>
            <p className={s.outro__text}>{text}</p>
            <div className={s.outro__signature}>
              <Signature
                onComplete={(signature) => { setComplete!(signature, true); }}
                instructions={signatureInstructions}
                placeholder={signaturePlaceholder}
              />
            </div>
          </Container>
        </div>
        )}
      <CSSTransition
        timeout={500}
        in={complete}
        unmountOnExit
        classNames={{ enter: s.enter, enterActive: s.enterActive }}
      >
        <div className={s.outro__content}>
          <Container>
            <h2 className={s('outro__heading', 'complete')}>{completedHeading}</h2>
            <p className={s.outro__text}>
              {completedText}
            </p>
            <div className={s.outro__share}>
              <InlineShare facebookLink={facebookLink} twitterLink={twitterLink} />
            </div>
          </Container>
        </div>
      </CSSTransition>

      {/*
      <div className={s.outro__countWrap}>
        {(complete && (count && count > 0)) && (
          <div className={s.outro__countInner}>
            <div className={s.outro__count}>{count} </div>
            <div className={s.outro__countText}>{countText || (<FormattedMessage id="pledge-count" />)}</div>
          </div>
        )}
      </div>
      */}
    </div>
  </>
  );
};
