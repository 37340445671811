import React from 'react';

import { Link } from 'components/link/Link';
import { Container } from 'components/layout/Container';

import Logo from 'assets/svg/logo-new.svg';
import Arrow from 'assets/svg/icons/arrow.svg';

import s from './Header.scss';

interface IProps {
  light?: boolean;
  logoLink?: string;
  navLink?: string;
  navLinkText?: string;
  showNav?: boolean;
}

export const Header = ({ light, logoLink, navLink, navLinkText, showNav = true }: IProps) => {
  const headerLink = logoLink || '/';

  return (
    <header className={s('header', { light })}>
      <Container fluid>
        <div className={s.header__inner}>
          <Link to={headerLink} className={s.header__logoWrap}>
            <Logo className={s.header__logo} />
          </Link>
          {(navLink && showNav) && (
            <Link to={navLink} className={s.header__navLink}>
              <Arrow className={s.header__navArrow} />{navLinkText}
            </Link>
          )}
        </div>
      </Container>
    </header>
  );
};
