import React, { useRef, useEffect, cloneElement } from 'react';

import { useOffset } from 'hooks/use-offset';

interface IProps {
  active?: boolean;
  children: React.ReactElement<any>;
  offsetType?: 'item' | 'itemInverted' | 'window' | 'windowInverted';
}

export const Offset = ({ offsetType, children }: IProps) => {
  const offsetEl = useRef<HTMLDivElement>(null);

  const offsetArg = (offsetType === 'item' || offsetType === 'itemInverted') ? 'item' : 'window';

  const offset = useOffset(offsetEl, offsetArg);

  useEffect(() => {
    if (!offsetEl.current) {
      return;
    }

    const computedOffset = offset ? (offsetType === 'itemInverted' || 'windowInverted') ? offset : (1 - offset) : 0;
    offsetEl.current!.style.setProperty('--offset', computedOffset.toString());

  }, [offset]);

  return (
    cloneElement(children, {
      ref: offsetEl,
    })
  );

};

Offset.defaultProps = {
  offsetType: 'item',
};
