import React from 'react';

import { Container } from 'components/layout/Container';
import { Row } from 'components/layout/Row';

import s from './Content.scss';

interface IProps {
  value: number;
  children?: React.ReactNode;
  text: string;
  activeSection?: boolean;
  backgroundText: string;
}

export const Content = ({ children, value, text, backgroundText }: IProps) => (
  <div className={s('content')}>
    <div className={s.content__inner}>
      <Container>
        <div className={s.content__backgroundText}>{backgroundText}</div>
        <Row>
          <div className={s.content__col}>
            <div className={s.content__item}>
              <h2 className={s.content__heading}>
                <span className={s.content__value}>{value}</span>
                <sub className={s.content__sub}>%</sub></h2>
              <p className={s.content__text}>{text}</p>
              {children}
            </div>
          </div>
        </Row>
      </Container>
    </div>
  </div>
);
