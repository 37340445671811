import React, { useEffect } from 'react';
import Helmet from 'react-helmet';
import { CSSTransition } from 'react-transition-group';

import { useKeyDown } from 'hooks/use-keydown';

import { CloseButton } from 'components/close-button/CloseButton';

import s from './OutroModal.scss';

interface IProps {
  active?: boolean;
  onClose?: () => void;
  children: React.ReactNode;
}

export const OutroModal = ({ active, children, onClose }: IProps) => {
  const keys = useKeyDown();

  useEffect(() => {
    if (keys.includes(27)) {
      onClose!();
    }

  }, [keys]);

  return (
  <>
    {active && <Helmet htmlAttributes={{ class: 'scroll-disabled' }} />}
    <CSSTransition
      timeout={500}
      in={active}
      unmountOnExit
      classNames={{ enter: s.enter, enterActive: s.enterActive, exit: s.exit }}
    >
      <div
        className={s.outroModal}
        role="dialog"
        aria-modal={active}
      >
        <div className={s.outroModal__close}>
          <CloseButton onClick={() => { onClose!(); }} />
        </div>
        {children}
      </div>
    </CSSTransition>
  </>
);
};
